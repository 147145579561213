<template>
  <v-container
    class="confirm-email-change-page pa-0"
    :class="{ mobile: $vuetify.breakpoint.smAndDown }"
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        lg="4"
        md="6"
        sm="10"
      >
        <v-card
          class="rounded-lg"
          elevation="10"
        >
          <v-container
            v-if="verifying"
            pa-0
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-progress-circular
                color="info"
                indeterminate
                size="40"
                width="3"
              />
            </v-row>
            <v-row
              class="progress-message"
              justify="center"
              no-gutters
            >
              <span>{{ $t('descriptions.changeEmailVerifying') }}</span>
            </v-row>
          </v-container>
          <v-container
            v-else
            pa-0
          >
            <v-container
              v-if="verificationSuccessful"
              pa-0
            >
              <v-row
                align="center"
                justify="center"
                no-gutters
              >
                <v-icon
                  color="success"
                  x-large
                >
                  fal fa-check-circle
                </v-icon>
              </v-row>
              <v-row
                align="center"
                justify="center"
                no-gutters
              >
                <p class="font-weight-medium ma-9 primary--text text-center">
                  {{ $t('descriptions.changeEmailSuccess') }}
                </p>
              </v-row>
              <v-row
                class="pb-8"
                no-gutters
              >
                <v-spacer />
                <v-col>
                  <v-btn
                    v-if="isAuthenticated"
                    color="secondary"
                    width="100%"
                    @click="close"
                  >
                    {{ $t('labels.closeTab') }}
                  </v-btn>
                  <template v-else>
                    <v-btn
                      v-if="!scheme || scheme === 'https'"
                      color="secondary"
                      :to="{ name: 'login' }"
                      width="100%"
                    >
                      {{ $t('labels.backToNursebrite') }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="secondary"
                      :href="`${scheme}://setting`"
                      width="100%"
                    >
                      {{ $t('labels.backToNursebrite') }}
                    </v-btn>
                  </template>
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
            <v-container
              v-else
              pa-0
            >
              <v-row
                align="center"
                justify="center"
                no-gutters
              >
                <v-icon
                  color="error"
                  x-large
                >
                  fal fa-times-circle
                </v-icon>
              </v-row>
              <v-row
                align="center"
                justify="center"
                no-gutters
              >
                <p class="font-weight-medium ma-9 primary--text text-center">
                  {{ $t('descriptions.changeEmailFail') }}
                </p>
              </v-row>
              <v-row
                class="pb-8"
                no-gutters
              >
                <v-spacer />
                <v-col>
                  <v-btn
                    v-if="isAuthenticated"
                    color="secondary"
                    width="100%"
                    @click="close"
                  >
                    {{ $t('labels.closeTab') }}
                  </v-btn>
                  <template v-else>
                    <v-btn
                      v-if="!scheme || scheme === 'https'"
                      color="secondary"
                      :to="{ name: 'login' }"
                      width="100%"
                    >
                      {{ $t('labels.backToNursebrite') }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="secondary"
                      :href="`${scheme}://setting`"
                      width="100%"
                    >
                      {{ $t('labels.backToNursebrite') }}
                    </v-btn>
                  </template>
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    newEmail: String, // eslint-disable-line vue/require-default-prop
    scheme: {
      default: 'https',
      type: String
    },
    token: String, // eslint-disable-line vue/require-default-prop
    uid: String // eslint-disable-line vue/require-default-prop
  },

  data () {
    return {
      verificationSuccessful: false,
      verifying: true
    };
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  mounted () {
    this.confirmEmailChange();
  },

  methods: {
    close () {
      window.close();
    },
    confirmEmailChange () {
      const userAndEmailInfo = {
        newEmail: this.newEmail,
        token: this.token,
        userId: this.uid
      };

      this.verifying = true;
      this.$store.dispatch('account/confirmEmailChange', userAndEmailInfo)
        .then(() => {
          this.verificationSuccessful = true;
        }).catch(() => {
          this.verificationSuccessful = false;
        }).finally(() => {
          this.verifying = false;
        });
    }
  }
};
</script>

<style lang="scss">
.confirm-email-change-page {
  background-image: url(../../assets/images/login-bg.svg);
  background-size: cover;
  height: 100%;

  .v-card {
    min-height: 180px;
    padding-top: 36px;

    .progress-message {
      margin-top: 36px;
    }
  }
}
</style>
