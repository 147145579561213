<template>
  <v-container>
    <portal to="page-title">
      <span class="title page-title text-truncate">
        {{ $t('labels.editProfile') }}
      </span>
    </portal>
    <ValidationObserver
      v-slot="{ invalid, passes }"
    >
      <v-form class="profile-edit">
        <v-row class="mt-sm-4">
          <v-spacer />
          <v-col
            cols="12"
            xl="6"
            lg="8"
            sm="10"
          >
            <v-card
              :class="['px-12', $vuetify.breakpoint.smAndDown ? 'py-2' : 'py-6']"
              :flat="$vuetify.breakpoint.smAndDown ? true : false"
              :style="{ background: $vuetify.breakpoint.smAndDown ? 'transparent' : 'default' }"
            >
              <v-row
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
                no-gutters
              >
                <v-col
                  class="text-left"
                >
                  <v-avatar
                    class="profile-pic ml-md-2"
                    :color="$store.state.account.profile.avatarBgColor"
                    size="60"
                  >
                    <span class="white--text headline">{{ avatar }}</span>
                  </v-avatar>
                  <!-- TODO: we will support users uploading their own profile picture later -->
                  <!-- <div
                    class="caption mt-3 text-no-wrap"
                  >
                    <a href="#">
                      {{ $t('labels.changeProfilePic') }}
                    </a>
                  </div> -->
                </v-col>
              </v-row>
              <v-row
                class="mt-9"
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col>
                  <div class="body-2 grey--text text--darken-3 font-weight-medium">
                    {{ $t('labels.account') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model.lazy="email"
                    class="mb-1"
                    dense
                    readonly
                    hide-details
                    :label="$t('labels.email')"
                    name="email"
                    outlined
                  >
                    <template v-slot:append>
                      <v-btn
                        class="mt-n1 change-email"
                        icon
                        small
                        @click="showChangeEmailDialog = true"
                      >
                        <v-icon small>
                          fal fa-pencil fa-fw
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <a
                    class="caption change-password"
                    @click="showChangePasswordDialog = true"
                  >
                    {{ $t('labels.changePassword') }}
                  </a>
                </v-col>
                <v-spacer />
              </v-row>
              <v-row
                class="mt-6"
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col>
                  <div class="body-2 grey--text text--darken-3 mb-0 font-weight-medium">
                    {{ $t('labels.info') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <VeeTextField
                    v-model.lazy="firstName"
                    dense
                    hide-details
                    :label="$t('labels.firstName')"
                    name="firstName"
                    outlined
                    rules="required|alpha_spaces|max:30"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <VeeTextField
                    v-model.lazy="lastName"
                    dense
                    hide-details
                    :label="$t('labels.lastName')"
                    name="lastName"
                    outlined
                    rules="required|alpha_spaces|max:150"
                  />
                </v-col>
              </v-row>
              <v-row
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <VeeTextField
                    v-model.lazy="alias"
                    dense
                    hide-details
                    :label="`${$t('labels.alias')} (${$t('labels.optional')})`"
                    name="alias"
                    outlined
                    rules="alpha_spaces|max:30"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="gender"
                    clearable
                    dense
                    hide-details
                    :items="[{ text: $t('labels.female'), value: 'female' }, { text: $t('labels.male'), value: 'male' }]"
                    :label="`${$t('labels.gender')} (${$t('labels.optional')})`"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row
                :dense="$vuetify.breakpoint.smAndDown ? true : false"
              >
                <v-col
                  cols="6"
                >
                  <VeeTextField
                    v-model.lazy="phonePersonal"
                    v-mask="phoneMask"
                    class="phone-number"
                    dense
                    hide-details
                    :label="$t('labels.phone')"
                    name="phone"
                    prefix="+1"
                    outlined
                    :rules="{ max: 30, phoneRegex_US: phoneRegex, required: true}"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <VeeSelect
                    v-model="timezone"
                    dense
                    hide-details
                    :items="availableTimezones"
                    :label="$t('labels.timezone')"
                    outlined
                    rules="required"
                  />
                </v-col>
              </v-row>
              <v-row :dense="$vuetify.breakpoint.smAndDown ? true : false">
                <v-col
                  class="text-right"
                  cols="12"
                >
                  <v-btn
                    class="mt-4 submit"
                    color="accent"
                    :disabled="invalid || updatingProfile"
                    @click.prevent="passes(updateUserAndProfile)"
                  >
                    <v-progress-circular
                      v-if="updatingProfile"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.update') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog
      v-model="showChangePasswordDialog"
      persistent
      width="500px"
    >
      <v-card
        id="changePassword"
        class="pa-1"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.changePassword') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="updatingPassword"
            icon
            small
            @click="closeChangePasswordDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="account-manage px-6">
              <v-row dense>
                <v-col class="py-0">
                  <VeeTextField
                    v-model="passwords.currentPassword"
                    :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                    dense
                    :label="$t('labels.currentPassword')"
                    name="currentPassword"
                    outlined
                    :rules="{ max: 32, required: true }"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="py-0">
                  <VeeTextField
                    v-model="passwords.newPassword"
                    :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                    dense
                    :label="$t('labels.passwordNew')"
                    name="newPassword"
                    outlined
                    :rules="{ max: 32, passwordRegex: passwordRegex, required: true }"
                    :type="showPassword ? 'text' : 'password'"
                    vid="newPassword"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="py-0">
                  <VeeTextField
                    v-model="passwords.newPasswordConfirm"
                    :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                    dense
                    :label="$t('labels.passwordNewConfirm')"
                    name="newPasswordConfirm"
                    outlined
                    :type="showPassword ? 'text' : 'password'"
                    :rules="{ passwordConfirmed: 'newPassword', required: true }"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-divider />
            <v-row dense>
              <v-col
                class="text-right pb-0 pt-4 px-7"
                cols="12"
              >
                <v-btn
                  class="mr-4"
                  :disabled="updatingPassword"
                  text
                  @click="closeChangePasswordDialog"
                >
                  {{ $t('labels.cancel') }}
                </v-btn>
                <v-btn
                  class="save submit"
                  color="accent"
                  :disabled="invalid || updatingPassword"
                  @click.prevent="passes(updatePassword)"
                >
                  <v-progress-circular
                    v-if="updatingPassword"
                    color="primary lighten-2"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.update') }}
                  </span>
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showChangeEmailDialog"
      persistent
      width="500px"
    >
      <v-card
        v-if="passwordConfirmed"
        id="changeEmail"
        class="pa-1"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.changeEmail') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="updatingEmail"
            icon
            small
            @click="closeChangeEmailDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="account-manage px-6">
              <v-row dense>
                <v-col class="py-0">
                  <v-text-field
                    v-model.lazy="email"
                    dense
                    readonly
                    :label="$t('labels.current')"
                    name="email"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="py-0">
                  <VeeTextField
                    v-model="newEmail"
                    dense
                    :label="$t('labels.new')"
                    name="newEmail"
                    outlined
                    :rules="{ email: true, max: 255, required: true }"
                    vid="newEmail"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="py-0">
                  <VeeTextField
                    v-model="newEmailConfirm"
                    dense
                    :label="$t('labels.confirm')"
                    name="newEmailConfirm"
                    outlined
                    :rules="{ email: true, emailConfirmed: 'newEmail', max: 255, required: true }"
                    vid="newEmailConfirm"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-row dense>
              <v-col class="pt-0 pb-4 px-7">
                <v-alert
                  class="caption dense font-weight-medium mx-0 mb-0"
                  color="info"
                  dense
                  outlined
                  text
                >
                  <v-icon
                    slot="prepend"
                    class="ml-n1 mr-3"
                    color="info"
                    size="12"
                  >
                    fas fa-info-circle
                  </v-icon>
                  {{ $t('descriptions.changeEmail') }}
                </v-alert>
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense>
              <v-col
                class="text-right pb-0 pt-4 px-7"
                cols="12"
              >
                <v-btn
                  class="mr-4"
                  :disabled="updatingEmail"
                  text
                  @click="closeChangeEmailDialog"
                >
                  {{ $t('labels.cancel') }}
                </v-btn>
                <v-btn
                  class="save submit"
                  color="accent"
                  :disabled="invalid || updatingEmail"
                  @click.prevent="passes(updateEmail)"
                >
                  <v-progress-circular
                    v-if="updatingEmail"
                    color="primary lighten-2"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.update') }}
                  </span>
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </ValidationObserver>
        </v-card-text>
      </v-card>
      <v-card
        v-else
        id="confirmPassword"
        class="pa-1"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.confirmYourPassword') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="confirmingPassword"
            icon
            small
            @click="closeChangeEmailDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-row dense>
              <v-col
                class="py-0 px-7"
                style="height: 150px"
              >
                <VeeTextField
                  v-model="passwords.currentPassword"
                  :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                  dense
                  :hint="$t('descriptions.changeEmailPasswordRequired')"
                  :label="$t('labels.currentPassword')"
                  name="currentPassword"
                  outlined
                  persistent-hint
                  :rules="{ max: 32, required: true }"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense>
              <v-col
                class="text-right pb-0 pt-4 px-7"
                cols="12"
              >
                <v-btn
                  class="mr-4"
                  :disabled="confirmingPassword"
                  text
                  @click="closeChangeEmailDialog"
                >
                  {{ $t('labels.cancel') }}
                </v-btn>
                <v-btn
                  class="save submit"
                  color="accent"
                  :disabled="invalid || confirmingPassword"
                  @click.prevent="passes(confirmPassword)"
                >
                  <v-progress-circular
                    v-if="confirmingPassword"
                    color="primary lighten-2"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.confirm') }}
                  </span>
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import { PHONE_REGEX_US, PWD_REGEX, TZ_NAMES_US } from '@/components/form_controls/constants';
import { getAvatar, processPhoneNumberForUIDisplay } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';

export default {
  components: {
    VeeSelect,
    VeeTextField
  },

  directives: {
    mask
  },

  props: {
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },

  data () {
    const phones = {
      phonePersonal: this.$store.state.account.profile.phonePersonal,
      phoneWork: this.$store.state.account.profile.phoneWork
    };
    processPhoneNumberForUIDisplay(phones);
    return {
      alias: this.$store.state.account.profile.alias,
      availableTimezones: TZ_NAMES_US,
      confirmingPassword: false,
      department: '',
      email: this.$store.state.account.profile.email,
      firstName: this.$store.state.account.profile.firstName,
      gender: this.$store.state.account.profile.gender,
      lastName: this.$store.state.account.profile.lastName,
      newEmail: '',
      newEmailConfirm: '',
      passwordConfirmed: false,
      passwords: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      passwordRegex: PWD_REGEX,
      phonePersonal: phones.phonePersonal,
      phoneWork: phones.phoneWork,
      phoneCountryCode: '+1',
      phoneMask: '(###) ###-####',
      phoneRegex: PHONE_REGEX_US,
      showChangeEmailDialog: false,
      showChangePasswordDialog: false,
      showPassword: false,
      timezone: this.$store.state.account.profile.timezone,
      updatingEmail: false,
      updatingPassword: false,
      updatingProfile: false
    };
  },

  computed: {
    avatar () {
      return getAvatar(this.$store.state.account.profile);
    },

    displayName () {
      let displayName = '';

      if (!this.firstName && !this.lastName) {
        // If neither first name nor last name is set, use email (without the domain name) as display name.
        displayName = this.email.split('@')[0];
      } else {
        displayName = this.firstName + ' ' + this.lastName;
      }

      return displayName;
    },

    jobStatusLabel () {
      if (this.jobStatusId) {
        const jobStatus = this.$store.getters['org/getJobStatusById'](this.jobStatusId);
        if (jobStatus) {
          return jobStatus.name;
        }
      }

      return '';
    },

    jobTypeAndDepartmentLabel () {
      const jobType = this.$store.state.account.profile.jobType;
      const department = this.$store.state.account.profile.department;
      return [jobType, department].filter(Boolean).join(' | '); // In case one of the value is empty, we don't want delimiter to be in the result.
    }
  },

  watch: {
    '$store.state.account.profile.email' (email) {
      this.email = email;
    }
  },

  methods: {
    closeChangeEmailDialog () {
      this.showChangeEmailDialog = false;
      this.showPassword = false;
      this.passwords.currentPassword = '';
      this.passwordConfirmed = false;
      this.newEmail = '';
      this.newEmailConfirm = '';
    },

    closeChangePasswordDialog () {
      this.showChangePasswordDialog = false;
      this.showPassword = false;
      this.passwords.currentPassword = '';
      this.passwords.newPassword = '';
      this.passwords.newPasswordConfirm = '';
    },

    confirmPassword () {
      this.confirmingPassword = true;
      this.dispatch('account/checkPassword', this.passwords.currentPassword).then(() => {
        this.passwordConfirmed = true;
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.passwordConfirmFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.confirmingPassword = false;
      });
    },

    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    updateEmail () {
      if (this.passwordConfirmed) {
        this.updatingEmail = true;
        this.dispatch('account/changeEmail', this.newEmail).then(() => {
          showStatus({
            text: this.$t('descriptions.emailChangeSuccess'),
            type: 'success'
          });
          this.closeChangeEmailDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.emailChangeFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.updatingEmail = false;
        });
      }
    },

    updatePassword () {
      if (this.passwords.currentPassword && this.passwords.newPassword && this.passwords.newPasswordConfirm) {
        this.updatingPassword = true;
        // Only changes password if user provided required inputs.
        this.dispatch('auth/changePassword', this.passwords).then(() => {
          this.closeChangePasswordDialog();

          showStatus({
            text: this.$t('descriptions.passwordChangeSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.passwordChangeFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.updatingPassword = false;
        });
      }
    },

    updateUserAndProfile () {
      this.updatingProfile = true;

      const payload = {
        alias: this.alias,
        firstName: this.firstName,
        gender: this.gender,
        lastName: this.lastName,
        timezone: this.timezone
      };

      if (this.phonePersonal) {
        payload.phonePersonal = this.phoneCountryCode + this.phonePersonal;
      } else {
        payload.phonePersonal = '';
      }

      if (this.phoneWork) {
        payload.phoneWork = this.phoneCountryCode + this.phoneWork;
      } else {
        payload.phoneWork = '';
      }

      payload.profileId = this.$store.state.account.profile.profileId;

      this.dispatch('account/updateUserAndProfile', payload).then(() => {
        showStatus({
          text: this.$t('descriptions.profileUpdateSuccess'),
          type: 'success'
        });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.profileUpdateFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.updatingProfile = false;
      });
    }
  }
};
</script>

<style lang="scss">
.job-status .v-chip__content,
.v-menu__content .v-list-item__title {
  font-weight: normal !important;
  text-transform: capitalize;
}

// At the moment we don't ask user to enter country code when entering their phone numbers.
// And we only handles US phone number. And the US country code is always displayed.
// inside the input box with greyed out color.
.phone-number .v-text-field__prefix {
  color: map-get($grey, 'base');
}
</style>
