var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("portal", { attrs: { to: "page-title" } }, [
        _c("span", { staticClass: "title page-title text-truncate" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("labels.editProfile")) + "\n    ")
        ])
      ]),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var passes = ref.passes
              return [
                _c(
                  "v-form",
                  { staticClass: "profile-edit" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mt-sm-4" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", xl: "6", lg: "8", sm: "10" } },
                          [
                            _c(
                              "v-card",
                              {
                                class: [
                                  "px-12",
                                  _vm.$vuetify.breakpoint.smAndDown
                                    ? "py-2"
                                    : "py-6"
                                ],
                                style: {
                                  background: _vm.$vuetify.breakpoint.smAndDown
                                    ? "transparent"
                                    : "default"
                                },
                                attrs: {
                                  flat: _vm.$vuetify.breakpoint.smAndDown
                                    ? true
                                    : false
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false,
                                      "no-gutters": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-left" },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "profile-pic ml-md-2",
                                            attrs: {
                                              color:
                                                _vm.$store.state.account.profile
                                                  .avatarBgColor,
                                              size: "60"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text headline"
                                              },
                                              [_vm._v(_vm._s(_vm.avatar))]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-9",
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-2 grey--text text--darken-3 font-weight-medium"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.account")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            dense: "",
                                            readonly: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.email"),
                                            name: "email",
                                            outlined: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mt-n1 change-email",
                                                        attrs: {
                                                          icon: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showChangeEmailDialog = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        fal fa-pencil fa-fw\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.email,
                                            callback: function($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email"
                                          }
                                        }),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "caption change-password",
                                            on: {
                                              click: function($event) {
                                                _vm.showChangePasswordDialog = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.changePassword"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-6",
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-2 grey--text text--darken-3 mb-0 font-weight-medium"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.info")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.firstName"),
                                            name: "firstName",
                                            outlined: "",
                                            rules:
                                              "required|alpha_spaces|max:30"
                                          },
                                          model: {
                                            value: _vm.firstName,
                                            callback: function($$v) {
                                              _vm.firstName = $$v
                                            },
                                            expression: "firstName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.lastName"),
                                            name: "lastName",
                                            outlined: "",
                                            rules:
                                              "required|alpha_spaces|max:150"
                                          },
                                          model: {
                                            value: _vm.lastName,
                                            callback: function($$v) {
                                              _vm.lastName = $$v
                                            },
                                            expression: "lastName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            label:
                                              _vm.$t("labels.alias") +
                                              " (" +
                                              _vm.$t("labels.optional") +
                                              ")",
                                            name: "alias",
                                            outlined: "",
                                            rules: "alpha_spaces|max:30"
                                          },
                                          model: {
                                            value: _vm.alias,
                                            callback: function($$v) {
                                              _vm.alias = $$v
                                            },
                                            expression: "alias"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            clearable: "",
                                            dense: "",
                                            "hide-details": "",
                                            items: [
                                              {
                                                text: _vm.$t("labels.female"),
                                                value: "female"
                                              },
                                              {
                                                text: _vm.$t("labels.male"),
                                                value: "male"
                                              }
                                            ],
                                            label:
                                              _vm.$t("labels.gender") +
                                              " (" +
                                              _vm.$t("labels.optional") +
                                              ")",
                                            outlined: ""
                                          },
                                          model: {
                                            value: _vm.gender,
                                            callback: function($$v) {
                                              _vm.gender = $$v
                                            },
                                            expression: "gender"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("VeeTextField", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: _vm.phoneMask,
                                              expression: "phoneMask"
                                            }
                                          ],
                                          staticClass: "phone-number",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.phone"),
                                            name: "phone",
                                            prefix: "+1",
                                            outlined: "",
                                            rules: {
                                              max: 30,
                                              phoneRegex_US: _vm.phoneRegex,
                                              required: true
                                            }
                                          },
                                          model: {
                                            value: _vm.phonePersonal,
                                            callback: function($$v) {
                                              _vm.phonePersonal = $$v
                                            },
                                            expression: "phonePersonal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("VeeSelect", {
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            items: _vm.availableTimezones,
                                            label: _vm.$t("labels.timezone"),
                                            outlined: "",
                                            rules: "required"
                                          },
                                          model: {
                                            value: _vm.timezone,
                                            callback: function($$v) {
                                              _vm.timezone = $$v
                                            },
                                            expression: "timezone"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      dense: _vm.$vuetify.breakpoint.smAndDown
                                        ? true
                                        : false
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-4 submit",
                                            attrs: {
                                              color: "accent",
                                              disabled:
                                                invalid || _vm.updatingProfile
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return passes(
                                                  _vm.updateUserAndProfile
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm.updatingProfile
                                              ? _c("v-progress-circular", {
                                                  attrs: {
                                                    color: "primary lighten-2",
                                                    indeterminate: "",
                                                    size: "22",
                                                    width: "2"
                                                  }
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("labels.update")
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-spacer")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500px" },
          model: {
            value: _vm.showChangePasswordDialog,
            callback: function($$v) {
              _vm.showChangePasswordDialog = $$v
            },
            expression: "showChangePasswordDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-1", attrs: { id: "changePassword" } },
            [
              _c(
                "v-card-title",
                { staticClass: "body-2 d-block mb-2" },
                [
                  _c("span", { staticClass: "body-2 font-weight-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("labels.changePassword")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right mt-n1",
                      attrs: {
                        disabled: _vm.updatingPassword,
                        icon: "",
                        small: ""
                      },
                      on: { click: _vm.closeChangePasswordDialog }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("\n            fal fa-times\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-0" },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          var passes = ref.passes
                          return [
                            _c(
                              "v-form",
                              { staticClass: "account-manage px-6" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "py-0" },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            "append-icon": _vm.showPassword
                                              ? "far fa-eye"
                                              : "far fa-eye-slash",
                                            dense: "",
                                            label: _vm.$t(
                                              "labels.currentPassword"
                                            ),
                                            name: "currentPassword",
                                            outlined: "",
                                            rules: { max: 32, required: true },
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.passwords.currentPassword,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.passwords,
                                                "currentPassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "passwords.currentPassword"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "py-0" },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            "append-icon": _vm.showPassword
                                              ? "far fa-eye"
                                              : "far fa-eye-slash",
                                            dense: "",
                                            label: _vm.$t("labels.passwordNew"),
                                            name: "newPassword",
                                            outlined: "",
                                            rules: {
                                              max: 32,
                                              passwordRegex: _vm.passwordRegex,
                                              required: true
                                            },
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            vid: "newPassword"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          model: {
                                            value: _vm.passwords.newPassword,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.passwords,
                                                "newPassword",
                                                $$v
                                              )
                                            },
                                            expression: "passwords.newPassword"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "py-0" },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            "append-icon": _vm.showPassword
                                              ? "far fa-eye"
                                              : "far fa-eye-slash",
                                            dense: "",
                                            label: _vm.$t(
                                              "labels.passwordNewConfirm"
                                            ),
                                            name: "newPasswordConfirm",
                                            outlined: "",
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            rules: {
                                              passwordConfirmed: "newPassword",
                                              required: true
                                            }
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.passwords.newPasswordConfirm,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.passwords,
                                                "newPasswordConfirm",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "passwords.newPasswordConfirm"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right pb-0 pt-4 px-7",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-4",
                                        attrs: {
                                          disabled: _vm.updatingPassword,
                                          text: ""
                                        },
                                        on: {
                                          click: _vm.closeChangePasswordDialog
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("labels.cancel")) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "save submit",
                                        attrs: {
                                          color: "accent",
                                          disabled:
                                            invalid || _vm.updatingPassword
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return passes(_vm.updatePassword)
                                          }
                                        }
                                      },
                                      [
                                        _vm.updatingPassword
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "primary lighten-2",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("labels.update")
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500px" },
          model: {
            value: _vm.showChangeEmailDialog,
            callback: function($$v) {
              _vm.showChangeEmailDialog = $$v
            },
            expression: "showChangeEmailDialog"
          }
        },
        [
          _vm.passwordConfirmed
            ? _c(
                "v-card",
                { staticClass: "pa-1", attrs: { id: "changeEmail" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.changeEmail")) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: {
                            disabled: _vm.updatingEmail,
                            icon: "",
                            small: ""
                          },
                          on: { click: _vm.closeChangeEmailDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var invalid = ref.invalid
                                var passes = ref.passes
                                return [
                                  _c(
                                    "v-form",
                                    { staticClass: "account-manage px-6" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  readonly: "",
                                                  label: _vm.$t(
                                                    "labels.current"
                                                  ),
                                                  name: "email",
                                                  outlined: ""
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c("VeeTextField", {
                                                attrs: {
                                                  dense: "",
                                                  label: _vm.$t("labels.new"),
                                                  name: "newEmail",
                                                  outlined: "",
                                                  rules: {
                                                    email: true,
                                                    max: 255,
                                                    required: true
                                                  },
                                                  vid: "newEmail"
                                                },
                                                model: {
                                                  value: _vm.newEmail,
                                                  callback: function($$v) {
                                                    _vm.newEmail = $$v
                                                  },
                                                  expression: "newEmail"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "py-0" },
                                            [
                                              _c("VeeTextField", {
                                                attrs: {
                                                  dense: "",
                                                  label: _vm.$t(
                                                    "labels.confirm"
                                                  ),
                                                  name: "newEmailConfirm",
                                                  outlined: "",
                                                  rules: {
                                                    email: true,
                                                    emailConfirmed: "newEmail",
                                                    max: 255,
                                                    required: true
                                                  },
                                                  vid: "newEmailConfirm"
                                                },
                                                model: {
                                                  value: _vm.newEmailConfirm,
                                                  callback: function($$v) {
                                                    _vm.newEmailConfirm = $$v
                                                  },
                                                  expression: "newEmailConfirm"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pt-0 pb-4 px-7" },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              staticClass:
                                                "caption dense font-weight-medium mx-0 mb-0",
                                              attrs: {
                                                color: "info",
                                                dense: "",
                                                outlined: "",
                                                text: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-n1 mr-3",
                                                  attrs: {
                                                    slot: "prepend",
                                                    color: "info",
                                                    size: "12"
                                                  },
                                                  slot: "prepend"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  fas fa-info-circle\n                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "descriptions.changeEmail"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-right pb-0 pt-4 px-7",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-4",
                                              attrs: {
                                                disabled: _vm.updatingEmail,
                                                text: ""
                                              },
                                              on: {
                                                click:
                                                  _vm.closeChangeEmailDialog
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("labels.cancel")
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "save submit",
                                              attrs: {
                                                color: "accent",
                                                disabled:
                                                  invalid || _vm.updatingEmail
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return passes(_vm.updateEmail)
                                                }
                                              }
                                            },
                                            [
                                              _vm.updatingEmail
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      color:
                                                        "primary lighten-2",
                                                      indeterminate: "",
                                                      size: "22",
                                                      width: "2"
                                                    }
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.update"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2499492620
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "pa-1", attrs: { id: "confirmPassword" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.confirmYourPassword")) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: {
                            disabled: _vm.confirmingPassword,
                            icon: "",
                            small: ""
                          },
                          on: { click: _vm.closeChangeEmailDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var invalid = ref.invalid
                              var passes = ref.passes
                              return [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 px-7",
                                        staticStyle: { height: "150px" }
                                      },
                                      [
                                        _c("VeeTextField", {
                                          attrs: {
                                            "append-icon": _vm.showPassword
                                              ? "far fa-eye"
                                              : "far fa-eye-slash",
                                            dense: "",
                                            hint: _vm.$t(
                                              "descriptions.changeEmailPasswordRequired"
                                            ),
                                            label: _vm.$t(
                                              "labels.currentPassword"
                                            ),
                                            name: "currentPassword",
                                            outlined: "",
                                            "persistent-hint": "",
                                            rules: { max: 32, required: true },
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.passwords.currentPassword,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.passwords,
                                                "currentPassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "passwords.currentPassword"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "text-right pb-0 pt-4 px-7",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-4",
                                            attrs: {
                                              disabled: _vm.confirmingPassword,
                                              text: ""
                                            },
                                            on: {
                                              click: _vm.closeChangeEmailDialog
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("labels.cancel")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "save submit",
                                            attrs: {
                                              color: "accent",
                                              disabled:
                                                invalid ||
                                                _vm.confirmingPassword
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return passes(
                                                  _vm.confirmPassword
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm.confirmingPassword
                                              ? _c("v-progress-circular", {
                                                  attrs: {
                                                    color: "primary lighten-2",
                                                    indeterminate: "",
                                                    size: "22",
                                                    width: "2"
                                                  }
                                                })
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("labels.confirm")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }